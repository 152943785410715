import { useStore } from "@nanostores/react";
import { notificationStore } from "../store";
import Alert from "./Alert.component";

export default function Notifications() {
  const $notificationStore = useStore(notificationStore);

  return (
    <div
      className="toast toast-top md:toast-bottom toast-center z-50 w-full sm:w-max"
      data-testid="Notifications"
    >
      {$notificationStore.notifications.map(
        (notification, notificationIndex) => {
          return (
            <Alert
              data-testid={`Notifications--${notificationIndex}`}
              key={notification.id}
              notification={notification}
            />
          );
        },
      )}
    </div>
  );
}
